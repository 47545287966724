/**
 * This functions sets the initial
 * height to main to push the footer down
 * and sets the fixed header.
 *
 * @package gwendt
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */

export function resetPage() {
    // Elements
    const
        body        = document.body,
        header      = document.getElementById('masthead'),
        fixedHeader = document.querySelector('#masthead.header-top'),
        footer      = document.getElementById('colophon'),
        main        = document.getElementById('main'),
        adminBar    = document.getElementById('wpadminbar');

    // Elements height
    let headerHeight    = header.offsetHeight;
    let footerHeight    = footer.offsetHeight;
    let windowHeight    = window.innerHeight;

    // Calculated heights
    let headfooHeight   = headerHeight + footerHeight;
    let mainHeight      = windowHeight - headfooHeight;

    // Adminbar case
    if(adminBar) {
        let adminHeight     = adminBar.offsetHeight;

        mainHeight      = mainHeight - adminHeight;
        //header.setAttribute('style', 'margin-top: ' + adminHeight + 'px');
    }

    main.setAttribute('style', 'min-height: ' + mainHeight + 'px');
}
