/**
 * This file registers all the sliders.
 *
 * @package gwendt
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */
import '../../../../../../../../node_modules/featherlight/release/featherlight.min';
import '../../../../../../../../node_modules/featherlight/release/featherlight.gallery.min';

export function featherlightConfiguration() {
    $('a.gallery').featherlightGallery({
        previousIcon: '&#9664;',     /* Code that is used as previous icon */
        nextIcon: '&#9654;',         /* Code that is used as next icon */
        galleryFadeIn: 100,          /* fadeIn speed when slide is loaded */
        galleryFadeOut: 300          /* fadeOut speed before slide is loaded */
    });
}
