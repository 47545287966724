import 'bootstrap';
import { resetPage } from "./theme/resetPage";
import { sliderConfiguration } from "./theme/sliders";
import { featherlightConfiguration } from "./theme/featherlight";

$(document).ready(() => {
    resetPage();
    sliderConfiguration();
    featherlightConfiguration();
});

$(window).on('resize', () => {
    resetPage();
});
