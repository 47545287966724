/**
 * This file registers all the sliders.
 *
 * @package gwendt
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */
import 'slick-carousel';

export function sliderConfiguration() {
    $('.block-slider').slick({
        dots: true,
        lazyLoad: 'ondemand',
        infinite: true,
        speed: 800,
        arrows: false,
        fade: true,
        rows: 0,
        cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
        adaptiveHeight: true
    });
}
